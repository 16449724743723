import React from 'react';
import { ImageViewerContext } from '../api/context';

function Image ({ src, alt }) {
  const [l, setl] = React.useState(true);
  const im = React.useRef();

  React.useEffect(() => {
    if (!im.current.complete) setl(true);
    im.current.onload = () => setl(false);
  }, [src])

  return (
    <div className='image-wrapper'>
      <img src={src} alt={alt} ref={im} className={l ? 'hide-o' : ''} />
      <div className={`image-overlay ${l ? 'show' : ''}`} />
    </div>
  );
}

export default function ImageViewer ({ pics, index, show }) {
  const api = React.useContext(ImageViewerContext);
  const modalRef = React.useRef(null);

  const len = pics ? pics.length : 0;
  const img = (pics && pics[index]) ? pics[index] : null;

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keyup', handleEscapePress);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keyup', handleEscapePress);
    };
  });

  function handleOutsideClick (event) {
    if (!show) return;
    if (modalRef.current && !modalRef.current.contains(event.target)) close();
  }

  function handleEscapePress (event) {
    if (event.keyCode === 27) close();
    if (event.keyCode === 37) prev();
    if (event.keyCode === 38) prev();
    if (event.keyCode === 39) next();
    if (event.keyCode === 40) next();
  }

  function next () {
    return api.setIndex((index + 1) % len)
  }

  function prev () {
    return api.setIndex((index - 1 + len) % len)
  }

  function close () {
    api.setShow(false);
  }

  if (!pics || !img) return <div />;
  return (
    <div className={`overlay ${show ? 'active' : ''}`}>
      <div className='modal box' ref={modalRef}>
        <div className='modal-image-wrapper'>
          <Image src={'https://gnsp.in/gallery/' + img.lg} alt={img.name} />
          <button className='prev-button' onClick={prev}>&lt;</button>
          <button className='next-button' onClick={next}>&gt;</button>
          <button className='close-button' onClick={close}>x</button>
        </div>
        <div className='padded'>
          <h3 className='t2 spread'>
            <span>{img.name}</span>
            <span>{img.year}</span>
          </h3>
          <ul className='skills-list sm'>
          {
            img.tags.map(tag => <li>{tag}</li>)
          }
          </ul>
        </div>
      </div>
    </div>
  );
}
