import React from 'react';

export default function Loading () {
  const canv = React.useRef();
  const screen = React.useRef();

  React.useLayoutEffect(() => {
    const h = canv.current.height = screen.current.offsetHeight;
    const w = canv.current.width = screen.current.offsetWidth;
    const ctx = canv.current.getContext('2d');

    const r = Math.ceil(h / 20);
    const c = Math.ceil(w / 20);
    const b = Array.from(Array(r), _ => Array(c).fill(0));
    ctx.fillStyle = '#0faa';

    function frame () {
      for (let i=0; i<r; i++) {
        for (let j=0; j<c; j++) {
          if (b[i][j]) {
            ctx.clearRect(j*20, i*20, 20, 20);
            b[i][j] = 0;
          }
          else {
            if (Math.random() < 0.04) {
              b[i][j] = 1;
              ctx.fillRect(j*20, i*20, 20, 20);
            }
          }
        }
      }
    }

    const intv = window.setInterval(frame, 100);
    return () => window.clearInterval(intv);
  }, []);

  return (
    <div className='loading-container' ref={screen}>
      <canvas width='100' height='100' ref={canv} />
      <h3 className='t2 lbl'>LOADING</h3>
    </div>
  );
}
