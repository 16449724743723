import React from 'react';
import cn from 'classd';

export const Cell = ({ span, mob, offset, moboffset, narrow, grow, className, children, ...rest }) => (
    <div
        className={cn`
            ${className}
            cell
            cell-des-n-${span}
            cell-des-d-${span}
            cell-mob-n-${mob || span}
            cell-mob-d-${mob || span}
            ${{
                [`cell-des-o-${offset}`] : offset,
                [`cell-mob-o-${moboffset || offset}`] : moboffset || offset,
                'cell-w-narrow' : narrow,
                'cell-w-grow' : grow
            }}
        `}
        { ...rest }
    >
        {children}
    </div>
);

export default Cell;
