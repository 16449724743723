import React from 'react';
import { Link } from 'react-router-dom';

export default function BlogpostSnippet ({ post }) {
  console.log(post);
  return (
    <div className='spaced blogpost-snippet'>
      <h4 className='date-str'>{post.created}</h4>
      <h3 className='t2'><Link to={`/blogpost/${post.id}`}>{post.title}</Link></h3>
      <ul className='skills-list sm'>
      {
        post.categories.map(cat => <li><Link to={`/tag/${cat}`}>{cat}</Link></li>)
      }
      </ul>
      <p dangerouslySetInnerHTML={{__html:post.abstract}} />
    </div>
  )
}
