import React from 'react';
import { Link } from 'react-router-dom';
import Cell from './cell';
import Emo from './emoji';

export default function () {
  return (
    <header className='header'>
      <Cell span={4} className='container box padded'>
        <Cell span={1} className='header-left'>
          <Link to='/'><h3><Emo name='flag-in' /> <span className='no-mob'>gnsp.in</span></h3></Link>
        </Cell>
        <Cell span={3} className='header-right'>
          <ul>
            <li><Link to='/projects'><Emo name='computer' className='no-mob' /> <span>PROJECTS</span></Link></li>
            <li><Link to='/art'><Emo name='art' className='no-mob' /> <span>ART</span></Link></li>
            <li><Link to='/blog'><Emo name='b' className='no-mob' /> <span>BLOG</span></Link></li>
          </ul>
        </Cell>
      </Cell>
    </header>
  );
}
