export default class GhournalBlogAPI {
  static files = [
    'timeline',
    'posts',
    'categories',
    'author',
    'blog',
  ];

  constructor (baseurl, onload) {
    this.baseURL = baseurl || 'https://gnsp.in/blog';
    this.root = {};
    this.state = {
      loaded: Object.assign ( ...GhournalBlogAPI.files.map( key => ({ [key]: false }) ))
    };

    this.onload_cbs = [];
    this.onload_cbs.push (onload);

    GhournalBlogAPI.files.forEach (this.fetchJSONFile);
  }

  onload = () => {
    this.onload_cbs.map ( cb => cb(this) );
    this.onload_cbs = [];
  }

  setOnloadTask = task => {
    if (this.loaded()) task();
    else this.onload_cbs.push (task);
  }

  path = str => `${this.baseURL}/${str}`;

  fetchJSONFile = (file, tc=0) => {
    const fileURL = `${this.baseURL}/${file}.json`;
    fetch(fileURL)
      .then( response => response.json() )
      .then( data => {
        this.root[file] = data;
        this.state.loaded[file] = true;

        if (this.loaded()) this.onload();
      })
      .catch(err => {
        if (tc > 3) return;
        console.log('Retrying to fetch '+file);
        window.setTimeout(() => this.fetchJSONFile(file, tc+1), 5000);
      });
  }

  loaded () {
    return ! Object.values(this.state.loaded).some(x => !x);
  }

  get posts () {
    if (this.loaded()) {
      let posts = this.root.posts;
      delete posts['filepath'];
      return posts;
    }
    return {};
  }

  get categories () {
    if (this.loaded()) {
      let categories = this.root.categories;
      delete categories['filepath'];
      return categories;
    }
    return {};
  }

  tagsList () {
    if (this.loaded()) {
      return Object.keys(this.categories).map ( tag => ({ tag, count: this.categories[tag].length }) );
    }
    return [];
  }

  sortedPosts () {
    if (this.loaded()) {
      let posts = this.root.posts;
      delete posts['filepath'];
      let ids = Object.keys (posts);
      return ids.map( id => ({ ...posts[id], id}) ).sort( (p1, p2) =>
        new Date(p2.created).getTime() - new Date(p1.created).getTime()
      );
    }
  }

  taggedPosts (tag) {
    if (this.loaded())
      return this.root.categories[tag].map ( id => ({
        id,
        ...this.posts[id]
      }));
    return [];
  }

  fetchPost (id, callback, tc=0) {
    this.setOnloadTask ( () => {
      const filepath = this.root.posts[id] ? this.root.posts[id].filepath : false;
      if (this.root[filepath]) callback (this.root[filepath]);
      else {
        fetch (this.path (filepath))
          .then ( res => res.json() )
          .then ( post => {
            this.root[filepath] = post;
            callback (post);
          })
          .catch ( err => {
            if (tc > 3) return;
            console.log('Retrying to fetch '+id);
            window.setTimeout(() => this.fetchPost (id, callback, tc+1), 5000);
          });
      }
    });
  }

}
