import React from 'react';
import { Redirect } from 'react-router-dom';
import Cell from './cell';
import Emo from './emoji';

class GameState {
  static X = 1;
  static O = -1;

  static CHOOSE = 0;
  static RUNNING = 1;
  static DONE = 2;

  static ROW = 0;
  static COL = 1;
  static DIA = 2;

  static instance = null;
  static getInstance () {
    if (!GameState.instance) GameState.instance = new GameState();
    return GameState.instance;
  }

  static getNewBoard () {
    return Array.from(Array(3), () => Array(3).fill(0));
  }

  static cloneBoard (board) {
    const b = Array.from(Array(3), () => Array(3).fill(0));
    for (let i=0; i<3; i++) for(let j=0; j<3; j++) b[i][j] = board[i][j];
    return b;
  }

  constructor () {
    this.cache = {};
    this.reset();
  }

  reset () {
    this.board = GameState.getNewBoard();
    this.playerSign = null;
    this.computerSign = null;
    this.turn = 0;
    this.stage = GameState.CHOOSE;
    this.winner = null;
    this.winarea = [null, null];
    this.move = 0;
  }

  setPlayerSign (sgn, cb) {
    this.playerSign = sgn;
    this.computerSign = sgn === GameState.X ? GameState.O : GameState.X;
    this.stage = GameState.RUNNING;
    if (sgn === GameState.X) this.turn = 0;
    else this.turn = 1;

    if (this.turn === 1) this.computerMove(cb);
  }

  playerMove (i, j, cb) {
    if (this.stage === GameState.RUNNING && this.turn === 0 && this.board[i][j] === 0) {
      this.board[i][j] = this.playerSign;
      const [rcd, ind, winner] = this.checkWinner(this.board);
      if (winner) {
        this.winner = this.playerSign === winner ? 0 : 1;
        this.winarea = [rcd, ind];
        this.stage = GameState.DONE;
      }
      else {
        this.move = this.move + 1;
        this.turn = (this.turn + 1) % 2;

        if (this.move === 9) this.stage = GameState.DONE;
        else window.setTimeout(() => this.computerMove(cb), 500);
      }
    }
  }

  computerMove (cb) {
    this.mmc = 0;
    const [sc, i, j] = this.minmax(GameState.cloneBoard(this.board), this.computerSign);
    console.log(this.mmc);
    if (i === null || j === null) return;

    this.board[i][j] = this.computerSign;
    const [rcd, ind, winner] = this.checkWinner(this.board);

    if (winner) {
      this.winner = this.playerSign === winner ? 0 : 1;
      this.winarea = [rcd, ind];
      this.stage = GameState.DONE;
    }
    else {
      this.move = this.move + 1;
      this.turn = (this.turn + 1) % 2;

      if (this.move === 9) this.stage = GameState.DONE;
    }
    if (cb) cb();
  }

  encodeBoard (brd, sgn) {
    let res = sgn === GameState.X ? 0 : 1;
    for (let i=0; i<3; i++) for(let j=0; j<3; j++) {
      res = res * 3;
      res = res + (brd[i][j] + 3) % 3;
    }
    return res;
  }

  minmax (brd, sgn) {
    this.mmc += 1;
    const enc = this.encodeBoard(brd, sgn);
    if (this.cache[enc]) return this.cache[enc];

    const [rcd, ind, winner] = this.checkWinner(brd);
    if (winner) {
      const sc = winner === sgn ? 1 : -1;
      this.cache[enc] = [sc, null, null];
      return [sc, null, null];
    }

    let score = -2;
    let move = [null, null];

    for (let i=0; i<3; i++) {
      for (let j=0; j<3; j++) {
        if (brd[i][j] === 0) {
          brd[i][j] = sgn;
          const [_sc, r, c] = this.minmax(brd, -sgn);
          const sc = -_sc;
          if (sc > score) {
            score = sc;
            move = [i, j];
          }
          brd[i][j] = 0;
        }
      }
    }

    if (move[0] === null) {
      this.cache[enc] = [0, null, null];
      return this.cache[enc];
    }
    this.cache[enc] = [score, ...move];
    return this.cache[enc];
  }

  checkWinner (board) {
    let rs = 0, cs = 0, ds1 = 0, ds2 = 0;
    for (let i=0; i<3; i++) {
      rs = 0;
      cs = 0;
      for (let j=0; j<3; j++) {
        rs += board[i][j];
        cs += board[j][i];
      }
      if (rs / GameState.X === 3) return [GameState.ROW, i, GameState.X];
      if (cs / GameState.X === 3) return [GameState.COL, i, GameState.X];
      if (rs / GameState.O === 3) return [GameState.ROW, i, GameState.O];
      if (cs / GameState.O === 3) return [GameState.COL, i, GameState.O];
      ds1 += board[i][i];
      ds2 += board[i][2-i];
    }
    if (ds1 / GameState.X === 3) return [GameState.DIA, 0, GameState.X];
    if (ds2 / GameState.X === 3) return [GameState.DIA, 1, GameState.X];
    if (ds1 / GameState.O === 3) return [GameState.DIA, 0, GameState.O];
    if (ds2 / GameState.O === 3) return [GameState.DIA, 1, GameState.O];
    return [null, null, null];
  }

  get state () {
    const self = this;
    return {
      board: GameState.cloneBoard(self.board),
      playerSign: self.playerSign,
      computerSign: self.computerSign,
      turn: self.turn,
      winner: self.winner,
      stage: self.stage,
      winarea: [ ...self.winarea],
    }
  }
}

function Game () {
  const [game, setGame] = React.useState(GameState.getInstance());
  const [state, setState] = React.useState(game.state);

  const hlt = GameState.getNewBoard();
  if (state.winarea[0] !== null) {
    for (let i=0; i<3; i++) {
      for (let j=0; j<3; j++) {
        if (state.winarea[0] === GameState.ROW && state.winarea[1] === i) hlt[i][j] = 1;
        if (state.winarea[0] === GameState.COL && state.winarea[1] === i) hlt[j][i] = 1;
      }
      if (state.winarea[0] === GameState.DIA && state.winarea[1] === 0) hlt[i][i] = 1;
      if (state.winarea[0] === GameState.DIA && state.winarea[1] === 1) hlt[i][2-i] = 1;
    }
  }

  function resetGame () {
    game.reset();
    setState(game.state);
  }

  function chooseSign (sgn) {
    return () => {
      game.setPlayerSign(sgn, () => setState(game.state));
      setState(game.state);
    }
  }

  function onCellClick (i, j) {
    return () => {
      game.playerMove(i, j, () => setState(game.state));
      setState(game.state);
    }
  }

  return (
    <>
      <div className='board'>
        <div className={state.stage === GameState.CHOOSE ? 'dim' : ''}>
        {
          state.board.map((row, i) => (<div className='board-row'>
            {
              row.map((cell, j) => (
                <div
                  className={`game-cell ${(state.stage === GameState.RUNNING && cell === 0) ? 'game-clickable' : ''} ${hlt[i][j] ? 'hlt' : ''}`}
                  onClick={onCellClick(i, j)}
                >
                {cell === GameState.X ? <Emo name='x' /> : cell === GameState.O ? <Emo name='o' /> : null}
                </div>
              ))
            }
          </div>))
        }
        </div>
        <div className={`board-overlay ${state.stage === GameState.CHOOSE ? 'active' : ''}`}>
          <div className='choose-sign game-clickable' onClick={chooseSign(GameState.X)}><Emo name='x' /></div>
          <div className='choose-sign game-clickable' onClick={chooseSign(GameState.O)}><Emo name='o' /></div>
        </div>
      </div>
      <div className='spaced game-info tac'>
      {
        state.stage === GameState.CHOOSE && <h3 className='t2'>Choose your sign to start the game</h3>
      }
      {
        (state.stage === GameState.RUNNING && state.turn === 0) &&
          <h3 className='t2'><Emo name='smiley' /> <span>Your turn</span></h3>
      }
      {
        (state.stage === GameState.RUNNING && state.turn === 1) &&
          <h3 className='t2'><Emo name='desktop_computer' /> <span>Computer's turn</span></h3>
      }
      {
        state.stage === GameState.DONE && <div>
          {
            state.winner === 0 ? <h3 className='t2'><Emo name='smiley' /> <span>Congrats ! You won</span></h3>
            : state.winner === 1 ? <h3 className='t2'><Emo name='desktop_computer' /> <span>Oops ! Computer won</span></h3>
            : <h3 className='t2'><Emo name='neutral_face' /> <span>Aww ! It's a draw</span></h3>
          }
        </div>
      }
      {
        state.stage === GameState.DONE && <h3 className='t2 game-clickable game-btn' onClick={resetGame}>PLAY AGAIN</h3>
      }
      </div>
    </>
  );
}

export default function ({ match : { path }}) {
  if (path === '*') return <Redirect to='/404' />
  return (
    <Cell span={1} className='container box padded spaced'>
      <div>
        <div className='tac'>
          <h1><Emo name='face_with_monocle' /></h1>
          <h1>404</h1>
          <h2 className='t2'>WHAT YOU WERE LOOKING FOR COULD NOT BE FOUND</h2>
          <p>However, you can play tic-tac-toe with the computer here</p>
        </div>
        <div className='spaced game-container'>
          <Game />
        </div>
      </div>
    </Cell>
  );
}
