import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import BlogpostSnippet from './blogpost-snippet';
import Cell from './cell';
import Emo from './emoji';
import Icon from './icon';
import Loading from './loading';
import blog_img from '../static/blog.svg';
import { BlogContext } from '../api/context';

export default function () {
  const blogapi = React.useContext(BlogContext);

  return (
    <>
      <Helmet>
        <title>Blog | gnsp.in</title>
      </Helmet>
      <Cell span={1} className='hero'>
        <div className='container box padded spaced'>
          <div className='projects-hero'>
            <div>
              <h1><Emo name='b' /> <span>BLOG</span></h1>
              <div>
                <a className='action-btn' href='https://dev.to/gnsp'><Icon b name='dev' /> <span>FOLLOW ON DEV</span></a>
              </div>
            </div>
            <img src={blog_img} alt='' />
          </div>
        </div>
      </Cell>
      <Cell span={4} mob={1} className='container spaced'>
        <Cell span={3} mob={1}>
          <div>
            <div className='padded main box'>
              <h2 className='t2 lbl mb'>|&gt; blogposts</h2>
              {
                blogapi ? blogapi.sortedPosts().map(post => <BlogpostSnippet post={post} />) : <Loading />
              }
            </div>
          </div>
        </Cell>
        <Cell span={1} className='sidebar'>
          <div>
            <div className='padded-sm box'>
              <h2 className='t2 lbl mb'>|&gt; tags</h2>
              <ul className='tags-list-sidebar'>
              {
                blogapi ? Object.keys(blogapi.categories).map(cat => (<li>
                  <Link to={`/tag/${cat}`}>{cat} ({blogapi.categories[cat].length})</Link>
                </li>)) : <Loading />
              }
              </ul>
            </div>
          </div>
        </Cell>
      </Cell>
    </>
  );
}
