import React from 'react';
const BlogContext = React.createContext({});
const GalleryContext = React.createContext({});
const ImageViewerContext = React.createContext({});

export {
  BlogContext,
  GalleryContext,
  ImageViewerContext,
};
