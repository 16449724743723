import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import GhournalBlogAPI from './api/blog';
import getGallery from './api/gallery';
import { BlogContext, GalleryContext, ImageViewerContext } from './api/context';

import Header from './components/header';
import Footer from './components/footer';
import HomePage from './components/home';
import ProjectsPage from './components/projects';
import GalleryPage from './components/gallery';
import BlogPage from './components/blog';
import BlogPostPage from './components/blogpost';
import BlogTagPage from './components/blogtag';
import NotFound from './components/not-found';

import ImageViewer from './components/image-viewer';
import ScrollToTop from './components/scroll-to-top';

function App() {
  const [blogAPI, setBlogAPI] = React.useState(null);
  const [gallery, setGallery] = React.useState(null);
  const [imageViewerState, setImageViewerState] = React.useState({});
  const imageViewerAPI = {
    setIndex: index => {
      setImageViewerState({ ...imageViewerState, index });
    },
    setShow: show => {
      setImageViewerState({ ...imageViewerState, show });
    },
    showIndex: index => {
      setImageViewerState({ index, show: true });
    },
  };

  React.useEffect(_ => {
    new GhournalBlogAPI('https://gnsp.in/blog', setBlogAPI);
    getGallery('https://gnsp.in/gallery/gallery.json', setGallery);
  }, []);

  return (
    <ImageViewerContext.Provider value={imageViewerAPI}>
      <BlogContext.Provider value={blogAPI}>
        <GalleryContext.Provider value={gallery}>
          <HashRouter>
            <ScrollToTop>
              <div className={`app-wrapper ${imageViewerState.show ? 'bg' : ''}`}>
                <Header />
                <Switch>
                  <Route exact path='/' component={HomePage} />
                  <Route exact path='/projects' component={ProjectsPage} />
                  <Route exact path='/art' component={GalleryPage} />
                  <Route exact path='/blog' component={BlogPage} />
                  <Route path='/blogpost/:postid' component={BlogPostPage} />
                  <Route path='/tag/:tag' component={BlogTagPage} />
                  <Route exact path='/404' component={NotFound} />
                  <Route path='*' component={NotFound} />
                </Switch>
                <Footer />
              </div>
            </ScrollToTop>
          </HashRouter>
        </GalleryContext.Provider>
      </BlogContext.Provider>
      <ImageViewer pics={gallery} index={imageViewerState.index} show={imageViewerState.show} />
    </ImageViewerContext.Provider>
  );
}

export default App;
