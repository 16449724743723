let gallery = null;
export default function getGallery (url, cb, tc=0) {
  if (gallery) return cb(gallery);
  return fetch(url)
    .then(res => res.json())
    .then(data => {
      gallery = data;
      gallery.reverse();
      cb(gallery);
    })
    .catch(err => {
      if (tc > 3) return;
      console.log('Retrying to fetch gallery.json');
      getGallery(url, cb, tc+1);
    })
}
