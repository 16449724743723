import React from 'react';
import Helmet from 'react-helmet';
import Cell from './cell';
import Emo from './emoji';
import Icon from './icon';
import Loading from './loading';
import gallery_img from '../static/gallery.svg';
import { GalleryContext, ImageViewerContext } from '../api/context';

export default function () {
  const gallery = React.useContext(GalleryContext);
  const imageViewerAPI = React.useContext(ImageViewerContext);

  function showImage (index) {
    return function () {
      imageViewerAPI.showIndex(index);
    }
  }

  return (
    <>
      <Helmet>
        <title>Artworks | gnsp.in</title>
      </Helmet>
      <Cell span={1} className='hero'>
        <div className='container box padded spaced'>
          <div className='projects-hero'>
            <div>
              <h1><Emo name='art' /> <span>ARTWORKS</span></h1>
              <div>
                <a className='action-btn' href='https://instagram.com/gnsp__'><Icon b name='instagram' /> <span>FOLLOW ON INSTAGRAM</span></a>
              </div>
            </div>
            <img src={gallery_img} alt='' />
          </div>

          { gallery ?
            <div className='spaced'>
              <div className='gallery-small'>
              {
                gallery.map((entry, index) => (<img
                  src={'https://gnsp.in/gallery/'+entry.sm}
                  alt={entry.name}
                  className='clickable'
                  onClick={showImage(index)}
                />))
              }
              </div>
            </div>
            :
            <Loading />
          }
        </div>
      </Cell>
    </>
  );
}
