import mocq from '../static/mocq.png';
import zom from '../static/zomato.svg';
import smallcase from '../static/smallcase.jpg';
import iron from '../static/iron.png';
import goog from '../static/goog.jpg';

const employmentData = [
  {
    name: 'Google',
    logo: goog,
    url: 'https://google.com',
    role: 'Software Engineer',
    time: 'December 2022 - Now',
    work: [
      {
        text: 'Contributes to Cloud Data Fusion / CDAP',
        skills: ['Java', 'Angular', 'Nodejs', 'React', 'GCP'],
      },
    ],
  },
  {
    name: 'Zomato',
    logo: zom,
    url: 'https://www.zomato.com',
    role: 'Software Development Engineer III',
    time: 'August 2020 - November 2022',
    work: [
      {
        text: 'Leading the web/platform team (a team of 7 developers)',
        skills: ['Team leadership', 'Software engineering'],
      },
      {
        text: 'Maintainer of the webfrontend/renderer microservice',
        skills: ['React', 'Nodejs', 'AWS', 'microservice'],
      },
      {
        text: 'Contributed to zomato careers-portal',
        skills: ['React', 'Nodejs', 'MongoDB', 'Redis', 'AWS'],
      },
      {
        text: 'Led the feedingindia-tech team (a team of 3 developers)',
        skills: ['Team leadership', 'Software engineering'],
      },
      {
        text: 'Contributed to www.feedingindia.org',
        skills: ['React', 'Nodejs', 'MySQL', 'AWS'],
      },
      {
        text: 'Contributed to zomato Auth SDK',
        skills: ['golang', 'Javascript', 'React'],
      },
      {
        text: 'Contributed to zomato consumer app backend',
        skills: ['PHP', 'MySQL', 'redis'],
      }
    ],
  },
  {
    name: 'smallcase',
    logo: smallcase,
    url: 'https://smallcase.com',
    role: 'Software Development Engineer',
    time: 'January 2020 - July 2020',
    work: [
      {
        text: 'Contributed to smallcase Gateway',
        skills: ['React', 'Preact', 'Nodejs', 'MongoDB'],
      },
      {
        text: 'Contributed to smallcase embed ',
        skills: ['React', 'Nodejs', 'OEmbed'],
      }
    ],
  },
  {
    name: 'Mocqtest',
    logo: mocq,
    url: 'https://mocqtest.com',
    role: 'Founder',
    time: 'January 2019 - December 2019',
    work: [
      {
        text: 'Developed the Mocqtest webapp ',
        skills: ['React', 'Nodejs', 'MongoDB', 'AWS'],
      },
      {
        text: 'Helped develop the business plans',
        skills: ['Team leadership', 'New business development'],
      }
    ],
  },
  {
    name: 'Zomato',
    logo: zom,
    url: 'https://www.zomato.com',
    role: 'Software Development Engineer',
    time: 'June 2017 - December 2018',
    work: [
      {
        text: 'Contributed to zomato Booklite',
        skills: ['ReactNative', 'ASP.NET'],
      },
      {
        text: 'Contributed to the web frontend for Recommendations System',
        skills: ['React', 'Python', 'Flask'],
      },
      {
        text: 'Contributed to zomato Web UI Kit (later sushi-web, a part of the sushi design system)',
        skills: ['React', 'Typescript', 'less'],
      },
      {
        text: 'Contributed to zomato PWA (features and optimizations)',
        skills: ['PHP', 'React', 'Redux', 'Nodejs'],
      },
      {
        text: 'Contributed to the CMS for zomato Sneakpeek',
        skills: ['PHP', 'MySQL', 'React'],
      }
    ],
  },
];

export default employmentData;
