import React from 'react';
import Helmet from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import BlogpostSnippet from './blogpost-snippet';
import Cell from './cell';
import Emo from './emoji';
import Icon from './icon';
import Loading from './loading';
import blog_img from '../static/blog.svg';
import { BlogContext } from '../api/context';

export default function ({ match: { params: { postid }}}) {
  const blogapi = React.useContext(BlogContext);
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    if (blogapi && blogapi.posts[postid]) blogapi.fetchPost(postid, setPost);
  }, [postid, blogapi]);

  React.useEffect(() => {
    window.setTimeout(window.__CPEmbed, 2000);
  }, [post]);

  if (blogapi && !blogapi.posts[postid]) return <Redirect to='/404' />;

  let similar = [];
  let similarset = {};
  if (post) {
    post.categories.forEach(cat => {
      const postlist = blogapi.categories[cat];
      postlist.forEach(pid => {
        if (similarset[pid] || postid === pid) return;
        similarset[pid] = true;
        similar.push({ id: pid, ...blogapi.posts[pid] });
      });
    });
  }

  return (
    <Cell span={4} mob={1} className='container'>
      <Cell span={3} mob={1}>
        { (post && post.cover_image) &&
          <Cell span={3} className='hero spaced'>
            <div className='box'>
              <img className='post-cover' src={post.cover_image} alt={post.title} />
            </div>
          </Cell>
        }
        <Cell span={3} className='spaced'>
          <div>
            <div className='padded main box'>
            { post ?
              <div>
                <h3 className='t2 date-str'>{post.created}</h3>
                <Helmet>
                  <title>{post.title} | gnsp.in</title>
                </Helmet>
                <h1>{post.title}</h1>
                <ul className='skills-list sm'>
                {
                  post.categories.map(cat => <li><Link to={`/tag/${cat}`}>{cat}</Link></li>)
                }
                </ul>
                <article className='spaced md-content' dangerouslySetInnerHTML={{__html: post.content}} />
              </div> : <Loading />
            }
            </div>
          </div>
        </Cell>
      </Cell>
      <Cell span={1} className='sidebar spaced'>
        <div>
          <div className='padded-sm box'>
            <h2 className='t2 lbl mb'>|&gt; tags</h2>
            <ul className='tags-list-sidebar'>
            {
              blogapi ? Object.keys(blogapi.categories).map(cat => (<li>
                <Link to={`/tag/${cat}`}>{cat} ({blogapi.categories[cat].length})</Link>
              </li>)) : <Loading />
            }
            </ul>
          </div>
          { (similar.length > 0) &&
            <div className='padded-sm box spaced'>
              <h2 className='t2 lbl mb'>|&gt; similar</h2>
              { similar.map(post => <BlogpostSnippet post={post} /> )}
            </div>
          }
        </div>
      </Cell>
    </Cell>
  );
}
