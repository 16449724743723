import React, {useState} from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import cn from 'classd';
import Cell from './cell';
import Emo from './emoji';
import Icon from './icon';
import Loading from './loading';
import BlogpostSnippet from './blogpost-snippet';
import selfie from '../static/selfie.png';
import employmentData from '../data/employmentData';

import { BlogContext, GalleryContext, ImageViewerContext } from '../api/context';

function OrgSnippet ({ name, logo, url, role, time, work }) {
  const [open, setOpen] = useState(false);

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <div className='org-snippet'>
      <header className={cn`${open && 'with-border-bottom'}`} onClick={toggleOpen}>
        <img src={logo} alt={name} />
        <div className='header-content'>
          <h3 className='t2'><a href={url}>{name}</a></h3>
          <h4>{role}</h4>
          <h4>{time}</h4>
        </div>
        <div className="org-header-btn">
          <span>{open ? 'COLLAPSE' : 'EXPAND'}</span>
          <Emo name="arrow_down" className={cn`md ${open && 'rotate180'}`} />
        </div>
      </header>
      {open &&
      <main className='org-content-wrapper'>
        <ul className='work-list'>
        { work.map(item => (
          <li>
            <p>{item.text}</p>
            <ul className='skills-list sm outlined'>
            { item.skills.map(skill => (
              <li>{skill}</li>
            ))}
            </ul>
          </li>
        ))}
        </ul>
      </main>
      }
    </div>
  );
}

export default function () {
  const blogapi = React.useContext(BlogContext);
  const gallery = React.useContext(GalleryContext);
  const imageViewerAPI = React.useContext(ImageViewerContext);

  function showImage (index) {
    return function () {
      imageViewerAPI.showIndex(index);
    }
  }

  return (
    <>
      <Helmet>
        <title>Ganesh Prasad's Portfolio | gnsp.in</title>
      </Helmet>
      <Cell span={4} mob={1} className='container'>
        <Cell span={3} mob={1}>
          <div>
            <Cell span={1} className='hero box padded spaced'>
              <Cell narrow mob={1} className='mob-center'>
                <div className='image-container'>
                  <img src={selfie} alt='Ganesh' className='hero-image' />
                </div>
              </Cell>
              <Cell narrow mob={1}>
                <div className='monologue mob-center'>
                  <h1><span>Hi ! </span> <Emo name='wave' className='lg' /> <span>I'm Ganesh.</span></h1>
                  <h2>I <Link to='/projects'>Code</Link>, <Link to='/art'>Paint</Link> and <Link to='/blog'>Write</Link> --> </h2>
                  <p className='t2 icons'>
                    <span><a href='https://github.com/GnsP'><Icon b name='github' /></a></span>
                    <span><a href='https://codepen.io/gnsp'><Icon b name='codepen' /></a></span>
                    <span><a href='https://dev.to/gnsp'><Icon b name='dev' /></a></span>
                    <span><a href='https://linkedin.com/in/ganesh-prasad-20319868/'><Icon b name='linkedin' /></a></span>
                    <span><a href='https://facebook.com/sharpandsage'><Icon b name='facebook-square' /></a></span>
                    <span><a href='https://quora.com/profile/Ganesh-Prasad-10'><Icon b name='quora' /></a></span>
                    <span><a href='https://instagram.com/gnsp__'><Icon b name='instagram' /></a></span>
                    <span><a href='https://twitter.com/_gnsp'><Icon b name='twitter' /></a></span>
                  </p>
                </div>
              </Cell>
            </Cell>
            <Cell span={1} className='main box padded spaced'>
              <div>
                <h2 className='t2 lbl'>|&gt; whoami</h2>
                <p className='bio'>
                  I am <strong>Ganesh Prasad Sahoo</strong>, a young creative mind from India. <br />
                  I created <strong><a href='https://ironscript.github.io'>Ironscript (Programming Language)</a></strong>, <a href='https://github.com/GnsP/ER-MarkUp'>
                  ER Markup</a>, <a href='https://github.com/GnsP/classd'> classd</a> and a bunch of other things.

                  <br /><br />
                  <strong>Scroll on to learn more about my creations and interests.</strong> <Emo name='star2' className='lg'/>
                </p>
              </div>
            </Cell>
            <Cell span={3} mob={1} className='box spaced view-pages-box'>
              <Cell span={1}>
                <Link to='/projects' className='padded centered'>
                  <span>
                    <Emo name='computer'/> <span>PROJECTS</span>
                  </span>
                </Link>
              </Cell>
              <Cell span={1} className='blt'>
                <Link to='/art' className='padded centered'>
                  <span>
                    <Emo name='art'/> <span>ARTWORKS</span>
                  </span>
                </Link>
              </Cell>
              <Cell span={1} className='blt'>
                <Link to='/blog' className='padded centered'>
                  <span>
                    <Emo name='b' /> <span>BLOG</span>
                  </span>
                </Link>
              </Cell>
            </Cell>
            <Cell span={1} className='main box padded spaced'>
              <div>
                <h2 className='t2 lbl'>|&gt; orgs</h2>
                <Cell span={1} mob={1} className='snippet-list'>
                { employmentData.map(org => (
                    <Cell span={1}>
                      <OrgSnippet { ...org } />
                    </Cell>
                  ))
                }
                </Cell>
              </div>
            </Cell>
            <Cell span={1} className='main box padded spaced'>
              <div>
                <h2 className='t2 lbl mb'>|&gt; skills</h2>
                <h3 className='t2'><Emo name='computer' /> <span>Programming</span></h3>
                <ul className='skills-list'>
                  <li>C / C++</li>
                  <li>Java</li>
                  <li>Python</li>
                  <li>PHP</li>
                  <li>go</li>
                  <li>Javascript (ES6)</li>
                  <li>Typescript (ES6)</li>
                  <li>CommonLISP</li>
                  <li>Prolog</li>
                  <li>Ironscript</li>
                </ul>
                <h3 className='t2'><Emo name='earth_americas' /> <span>Web Technologies</span></h3>
                <ul className='skills-list'>
                  <li>NodeJS</li>
                  <li>React</li>
                  <li>Angular</li>
                  <li>RiotJS</li>
                  <li>HTML5 / Pug</li>
                  <li>CSS / SASS / LESS</li>
                  <li>Webpack / Gulp / Rollup</li>
                  <li>MySQL</li>
                  <li>MongoDB</li>
                  <li>Websockets / Socket.io</li>
                  <li>Nextjs</li>
                  <li>Styled components</li>
                </ul>
                <h3 className='t2'><Emo name='brain' /> <span>Misc</span></h3>
                <ul className='skills-list'>
                  <li>Linux</li>
                  <li>AWS</li>
                  <li>GCP</li>
                  <li>gcc toolchain</li>
                  <li>git</li>
                  <li>npm</li>
                  <li>vim</li>
                  <li>Blender</li>
                  <li>GIMP</li>
                  <li>Inkscape</li>
                </ul>
              </div>
            </Cell>
            <Cell span={1} className='main box padded spaced'>
              <div>
                <h2 className='t2 lbl mb'>|&gt; artworks</h2>
                <div className='gallery-small'>
                {
                  gallery ? gallery.slice(0,9).map((entry, index) => (<img
                    src={'https://gnsp.in/gallery/'+entry.sm}
                    alt={entry.name}
                    className='clickable'
                    onClick={showImage(index)}
                  />)) : <Loading />
                }
                </div>
                <h3>
                  <Link to='/art' className='link-btn'>VIEW ALL</Link>
                </h3>
              </div>
            </Cell>
            <Cell span={1} className='main box padded spaced'>
              <div>
                <h2 className='t2 lbl mb'>|&gt; blogposts</h2>
                {
                  blogapi ? blogapi.sortedPosts().map(post => <BlogpostSnippet post={post} />) : <Loading />
                }
                <h3>
                  <Link to='/blog' className='link-btn'>VIEW ALL</Link>
                </h3>
              </div>
            </Cell>
          </div>
        </Cell>

        <Cell span={1} className='sidebar'><div>
          <Cell span={1}>
            <div className='box padded-sm spaced pulsar'>
              <h2 className='t2 lbl mb'>
                |&gt; hireme
              </h2>
              <div className='spread'>
                <h4 className='nm'>Happily employed</h4>
                <span className='dot green' />
              </div>
              <h3 className='nm'><a href='https://gnsp.in/gnsp.pdf' className='link-btn'><Icon name='file-alt' /> <span>DOWNLOAD MY CV</span></a></h3>
              <div className='divider-text'> AND / OR </div>
              <h3 className='nm'><a href='mailto:gnsp.ironscript@gmail.com' className='link-btn nm'><Icon name='envelope' /> <span>EMAIL ME</span></a></h3>
            </div>
          </Cell>

          <Cell span={1}>
            <div className='box padded-sm spaced'>
              <h2 className='t2 lbl'>
                |&gt; hacks
              </h2>
              <ul className='hacks-list'>
                <li>
                    <h3><a href='https://gnsp.in/404-road-not-found'>404 : Road Not Found</a></h3>
                  <p>
                    A small game in JS using HTML5 canvas API and WebAudio API for <strong>js13k-games 2020</strong>.
                  </p>
                </li>
                <li>
                  <h3><a href='https://gnsp.in/covid19'>COVID-19 Tracker (India)</a></h3>
                  <p>
                    A COVID-19 tracker with predictions to analyze the spread of COVID-19 in India.
                  </p>
                </li>
                <li>
                  <h3><a href='https://gnsp.in/web-file-manager'>Web File Manager</a></h3>
                  <p>
                    An UI demo of a web based file manager written in <strong>React</strong> and <strong>Redux</strong>.
                  </p>
                </li>
                <li>
                  <h3><a href='https://codepen.io/gnsp/pen/YzKvWPj'>15 Puzzle</a></h3>
                  <p>
                    A 15 Puzzle game (rearrange 15 square tiles to form a picture) made using <strong>React Hooks</strong>.
                  </p>
                </li>
                <li>
                  <h3><a href='https://codepen.io/gnsp/pen/YzKvWPj'>Neon Clock</a></h3>
                  <p>
                    An animated, glowing and speaking timepiece made using <strong>React Hooks</strong>.
                  </p>
                </li>
                <li>
                  <h3><a href='https://gnsp.in/thanos'>Thanos</a></h3>
                  <p>
                    Clone of an easter egg in the Google Search page for 'thanos', made in vanilla JS and canvas API.
                  </p>
                </li>
                <li>
                  <h3><a href='https://gnsp.in/Typing-Speed-Test'>Typing Speed Viz</a></h3>
                  <p>
                    Graphical and realtime typing speed visualizer made using <strong>Ironscript</strong>.
                  </p>
                </li>
                <li>
                  <h3><a href='https://gnsp.in/countries'>Countries</a></h3>
                  <p>
                    Listing of all countries in the world, with their flags, currency, area, population and population
                    density. (Scrapped from publicly available sources)
                  </p>
                </li>
                <li>
                  <h3><a href='https://codepen.io/gnsp/pen/oNveGGj'>Flag of India</a></h3>
                  <p>
                    Making the Indian flag using Pug and Less recursive mixins.
                  </p>
                </li>
                <li>
                  <h3><Link to='/projects' className='link-btn'>VIEW ALL</Link></h3>
                </li>
              </ul>
            </div>
          </Cell>
          <Cell span={1}>
            <div className='box padded-sm spaced'>
              <h2 className='t2 lbl'>
                |&gt; repos
              </h2>
              <ul className='hacks-list'>
                <li>
                  <h3><a href='https://ironscript.github.io'>Ironscript</a></h3>
                  <p>
                    A functional, asynchronous, minimal, interpreted programmng language for rapid web app development.
                  </p>
                </li>
                <li>
                  <h3><a href='https://npmjs.com/package/classd'>classd</a></h3>
                  <p>
                    A fast and modern, template literal based alternative to classNames.
                  </p>
                </li>
                <li>
                  <h3><a href='https://github.com/gnsp/odia-keyboard'>Odia Keyboard Driver</a></h3>
                  <p>
                    A phonetic keyboard input driver for Odia written in JS.
                  </p>
                </li>
                <li>
                  <h3><a href='https://github.com/gnsp/grille'>Grille</a></h3>
                  <p>
                    A flexbox based easy-to-use grid system for React.
                  </p>
                </li>
                <li>
                  <h3><a href='https://github.com/GnsP/ER-MarkUp'>ER Markup</a></h3>
                  <p>
                    A small markup language for describing Entities and Relationships, compiles to Graphviz dot.
                  </p>
                </li>
                <li>
                  <h3><a href='https://www.npmjs.com/package/ghournal'>Ghournal</a></h3>
                  <p>
                    An API only blog engine for github pages.
                  </p>
                </li>
                <li>
                  <h3><a href='https://github.com/GnsP/GNS--META_LISCPP'>Metaliscpp</a></h3>
                  <p>
                    A C++ template metaprogramming library, similar to and predating boost::hana.
                  </p>
                </li>
                <li>
                  <h3><a href='https://github.com/gnsp?tab=repositories' className='link-btn'>VIEW ALL ON GITHUB</a></h3>
                </li>
              </ul>
            </div>
          </Cell>
        </div></Cell>
      </Cell>
    </>
  );
}
