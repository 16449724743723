import React from 'react';
import Icon from './icon';
import Cell from './cell';

export default function () {
  return (
    <div className='footer'>
      <Cell span={1} className='container box padded spaced'>
          <div className='tac spaced'>
            <h3 className='t2'>Considering hiring me ?</h3>
            <h3 className='nm'><a href='https://gnsp.in/gnsp.pdf' className='action-btn'><Icon name='file-alt' /> <span>DOWNLOAD MY CV</span></a></h3>
            <div className='divider-text'> AND / OR </div>
            <h3 className='nm'><a href='mailto:gnsp.ironscript@gmail.com' className='action-btn nm'><Icon name='envelope' /> <span>EMAIL ME</span></a></h3>
          </div>
          <div className='spaced tac'>
            <p>This website is powered by <b>React</b>, <b>classd</b>, <b>ghournal</b> and handcrafted awesomeness.</p>
            <p>&copy; 2019 Ganesh Prasad</p>
          </div>
      </Cell>
    </div>
  );
}
